import React, { useContext, useEffect } from 'react'
import { graphql } from 'gatsby'
import { globals, s, colors } from '../clever-ui-kit/style'
import { Grid } from '@material-ui/core'
import { T } from '../clever-ui-kit/typo'
import SideLine from '../clever-ui-kit/elements/SideLine/SideLine'
// import ContactButton from '../clever-ui-kit/buttons/ButtonBig'
// CONTEXT
import { StateContext, DispatchContext, initialState } from '../context'

// COMPONENTS CONST
import SEO from '../components/seo'
import { WrapperContainer, WrapperSection } from '../clever-ui-kit/wrappers'
import { ButtonMain } from '../clever-ui-kit/buttons/'
import Separator from '../clever-ui-kit/elements/Separator/separator'
export default function CareerTemplate({ data }) {
  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)
  const theme = state?.theme ? state.theme : initialState.theme

  const career = data.thisCareer?.frontmatter
  // const careerHtml = data.thisCareer?.html
  // console.log('here', career)
  // console.log(career)
  useEffect(() => {
    dispatch({ type: 'SET_MENU', payload: false })
  }, [])

  useEffect(() => {
    if (career.language)
      dispatch({ type: 'SET_LANGUAGE', payload: career.language })
  }, [])
  return (
    <>
      <SEO
        title={career.seo_title || career.title}
        description={career.seo_desc || `Transport ${career.title}`}
      />
      {/* <IntroPage
        title={career.title}
        description={career.description}
        subtitle={career.subtitle}
        image={career.image}
        theme={theme}
      /> */}
      <WrapperSection
        // id={slug_section}
        noMarginTop
        outside={<SideLine theme={theme}>{career.city}</SideLine>}>
        <WrapperContainer
          theme={theme}
          fullMobile
          extraCss={sContainer}
          outside={<SideLine theme={theme}>{career.subtitle}</SideLine>}>
          <div css={sSpace}>
            <Grid container>
              <Grid item xs={12} md={12} lg={12} css={sSection(theme).even}>
                <div>
                  <T
                    d={32}
                    m={24}
                    mb={1}
                    bold
                    className="about-title"
                    variant="h4">
                    {career.title}
                  </T>
                  <T o={0.5} mb={2} m={14} normal className="about-desc">
                    {career.description}
                  </T>
                  {/* <T
                    o={0.5}
                    mb={2}
                    m={14}
                    normal
                    css={{ opacity: 1, fontWeight: 700 }}>
                    Nie ma oddziału w Twoimi mieście? Skontaktuj się z nami!
                  </T> */}
                </div>
                <Separator />
                <div>
                  <T
                    d={14}
                    m={24}
                    mt={2}
                    mb={0.25}
                    bold
                    className="about-title"
                    variant="h6">
                    {career.responsibilitiesTitle}:
                  </T>
                  <ul>
                    {career.responsibilities.map((el, id) => (
                      <li key={id}>{el.resposnsibility}</li>
                    ))}
                  </ul>
                </div>
                <Separator />
                <div>
                  <T
                    d={14}
                    m={24}
                    mt={2}
                    mb={0.25}
                    bold
                    className="about-title"
                    variant="h6">
                    {career.requirementsTitle}:
                  </T>
                  <ul>
                    {career.requirements.map((el, id) => (
                      <li key={id}>{el.requirement}</li>
                    ))}
                  </ul>
                </div>
                <Separator />
                <div>
                  <T
                    d={14}
                    m={24}
                    mt={2}
                    mb={0.25}
                    bold
                    className="about-title"
                    variant="h6">
                    {career.offersTitle}:
                  </T>
                  <ul>
                    {career.offers.map((el, id) => (
                      <li key={id}>{el.offer}</li>
                    ))}
                  </ul>
                </div>
                {career.careerText && (
                  <>
                    <Separator />
                    <div>
                      <T d={16} m={16} mt={2} mb={2} bold>
                        {career.careerText}
                      </T>
                    </div>{' '}
                  </>
                )}
                {career.careerClause && (
                  <>
                    <Separator />
                    <div>
                      <T d={10} m={10} mt={2} mb={0.25}>
                        {career.careerClause}
                      </T>
                    </div>{' '}
                  </>
                )}
              </Grid>
            </Grid>
          </div>
          <div css={doubleButton}>
            <div>
              <ButtonMain
                language={career.language}
                theme={theme}
                button={career.button_first}
                extraCss={extraCss}>
                {career.button_first.text}
              </ButtonMain>
            </div>
            {console.log(career.button_second)}
            <div>
              <ButtonMain
                theme={theme}
                link={`mailto:${
                  career.careerEmail || 'hr@starklog.pl'
                }?subject=Kariera - ${career.title}`}
                extraCss={extraCss}>
                {career.button_second.text}
              </ButtonMain>
            </div>
          </div>
        </WrapperContainer>
      </WrapperSection>
    </>
  )
}
const extraCss = {
  padding: '1.5rem 10rem',
  [s.sm_down]: { padding: '1.275rem 5rem' },
}
const doubleButton = {
  marginTop: '4rem',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: '15%',
  [s.sm_down]: { gap: '10%' },
  [s.xs]: { gap: 'unset', flexDirection: 'column', marginTop: 'unset' },
  div: { [s.xs]: { marginTop: '1.5rem' } },
}
// const sTheme = (theme) => ({
//   backgroundColor: colors[theme].container.concat(alpha[80]),
// })
const sContainer = {
  [s.sm_down]: {
    paddingBottom: globals.spacing.inside.mobile,
  },
  [s.md]: {
    paddingBottom: globals.spacing.inside.desktop,
  },
}

const sSection = (theme) => ({
  '.about-title, .about-subtitle': {
    color: colors[theme].main,
  },

  [s.sm_down]: {
    paddingBottom: globals.spacing.inside.mobile,
  },

  spacing: {
    [s.sm_down]: {
      paddingBottom: globals.spacing.inside.mobile,
    },
    [s.md]: {
      paddingBottom: globals.spacing.inside.desktop,
    },
  },

  even: {
    '.about-desc': {
      [s.md]: {
        marginRight: '4rem',
      },
    },
  },

  odd: {
    [s.md]: {
      order: 1,
      textAlign: 'right',
    },
    '.about-desc': {
      [s.md]: {
        marginRight: '0rem',
        marginLeft: '4rem',
      },
    },
  },
})

const sSpace = {
  [s.sm_down]: {
    '&:not(last-of-type)': { paddingBottom: globals.spacing.inside.mobile * 2 },
  },
  [s.md]: {
    '&:not(last-of-type)': {
      paddingBottom: globals.spacing.inside.desktop * 2,
    },
  },
}

// const sImageContainer = {
//   flexShrink: 1,
//   width: 320,
//   height: 320,
//   position: 'relative',
//   borderRadius: '4rem',

//   wrapper: {
//     position: 'absolute',
//     top: 0,
//     right: 0,
//     width: '100%',
//     height: '100%',
//     borderRadius: 'inherit',
//   },
//   imgStyle: {},
// }
export const query = graphql`
  query($title: String!) {
    thisCareer: markdownRemark(
      fileAbsolutePath: { regex: "/careersOffers/" }
      frontmatter: { title: { eq: $title } }
    ) {
      html
      frontmatter {
        title
        city
        description
        # subtitle
        # service_subtitle
        language
        slug_section
        seo_title
        seo_desc

        image {
          name
          publicURL
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        careerEmail
        careerText
        careerClause
        responsibilitiesTitle
        requirementsTitle
        offersTitle
        responsibilities {
          resposnsibility
        }
        requirements {
          requirement
        }
        offers {
          offer
        }
        button_first {
          text
          uppercase
          link_page
          link_section
          link_external
        }
        button_second {
          text
          uppercase
          link_page
          link_section
          link_external
        }
      }
    }
  }
`
