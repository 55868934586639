import React from 'react'
const Separator = ({}) => {
  return (
    <div css={{ display: 'flex', justifyContent: 'center' }}>
      <div
        css={{
          borderBottom: '1px solid gray',
          width: '100%',
          opacity: 0.3,
        }}
      />
    </div>
  )
}
export default Separator
